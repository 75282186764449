import PrimeVue from "primevue/config";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import ToastService from "primevue/toastservice";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Chips from "primevue/chips";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";
import { FloatLabelInput } from "@cumulus/components";
import Tag from "primevue/tag";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Toast from "primevue/toast";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import OverlayPanel from "primevue/overlaypanel";
import { FloatLabelDropdownPanel } from "@cumulus/components";

import { vue3Debounce } from "vue-debounce";

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();
  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Tag", Tag);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("FloatLabelInput", FloatLabelInput);
  app.component("Toast", Toast);
  app.component("PrimeButton", Button);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("Dropdown", Dropdown);
  app.component("Chips", Chips);
  app.component("Checkbox", Checkbox);
  app.component("PrimeTextArea", TextArea);
  app.component("InputNumber", InputNumber);
  app.component("PrimeDialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("PrimeMenu", Menu);
  app.component("OverlayPanel", OverlayPanel);
  app.component("PrimeTextarea", TextArea);

  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);

  app.directive("tooltip", Tooltip);
  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    })
  );
}
