<template>
  <div data-testid="summary" class="w-full flex justify-content-end">
    <div class="c-card c-card-background w-3">
      <div class="grid">
        <div class="col-6">
          <div class="flex h-full">
            <div class="flex align-items-center align-self-end">
              <div>{{ t("freight") }}:</div>
              <InputNumber
                :modelValue="freight"
                :min-fraction-digits="2"
                :max-fraction-digits="2"
                placeholder="0"
                :allow-empty="false"
                inputClass="w-6rem text-center ml-3"
                data-testid="freight-input"
                mode="currency"
                currencyDisplay="narrowSymbol"
                :currency="currencyIso"
                @update:modelValue="onFreightChanged"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="flex flex-column justify-content-between">
            <div class="flex">
              <p>{{ t("sum-in-reception") }}:</p>
              <p data-testid="sum-in-reception" class="ml-2">
                {{ n(summary.receivedQuantitySum, "currency", props.currencyIso) }}
              </p>
            </div>
            <div class="flex flex-column">
              <div data-testid="currency">{{ t("total", { currency: props.currencyIso }) }}:</div>
              <div>
                <span data-testid="sum-inc-freight" class="text-xl mr-2">{{
                  n(summary?.receivedQuantitySumIncFreight, "currency", props.currencyIso)
                }}</span>
                <span>{{ t("inclFreight") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CalculatedGoodsReceptionSummaryResponse } from "../repository/goods-reception/model/CalculatedGoodsReceptionSummaryResponse";

const { t, n } = useI18n();
const freight = ref(0);

const onFreightChanged = (value: number) => {
  freight.value = value;
  emit("freight-changed", value);
};

const emit = defineEmits<{
  (e: "freight-changed", value: number): void;
}>();

const props = defineProps<{
  currencyIso: string;
  summary: CalculatedGoodsReceptionSummaryResponse;
}>();

const currencyIso = computed(() => (props.currencyIso ? props.currencyIso : "NOK"));
</script>
