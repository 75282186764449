<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('title.search-goods-reception')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      :value="purchaseOrders"
      dataKey="id"
      data-testid="purchase-search-result"
      class="c-compact-datatable"
      :paginator="true"
      :lazy="true"
      :loading="loading"
      :rows="pageSize"
      :totalRecords="totalHits"
      @row-dblclick="onRowDblClick"
      @row-select="onRowSelected"
      :currentPageReportTemplate="
        t('search.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :sortOrder="sortOrder"
      :sortField="sortField"
      removableSort
      @sort="onSort"
      @page="onPage"
      :rowsPerPageOptions="[50, 100]"
      ref="searchPurchaseOrder"
      v-model:selection="selectedPurchaseOrder"
      :stripedRows="true"
      selectionMode="single"
      tabindex="0"
      :rowClass="addGlobalSearchClass"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === PurchaseOrderLinesColumns.EstimatedArrivalDate">
            <span :data-testid="`estimated-arrival-date-${index}`">{{ d(data[field]) }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.PurchaseOrderNumber">
            <span
              :data-testid="`purchase-order-number-${index}`"
              class="c-purchase-order-number-text"
              @click="onPurchaseNumberClicked(data)"
              >{{ data[field] }}</span
            >
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.Status">
            <span :data-testid="`purchase-order-status-${index}`">{{ getStatusTranslation(data) }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.SupplierNumber">
            <span :data-testid="`supplier-number-${index}`">{{ data.supplier.supplierNumber }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.SupplierName">
            <span :data-testid="`supplier-name-${index}`">{{ data.supplier.name }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.PurchaseOrderReference">
            <span :data-testid="`purchase-order-reference-${index}`">{{ data[field] }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.OpenLines">
            <OpenLines :purchaseOrderLines="data.purchaseOrderLines" :index="index" />
          </template>
          <template v-else-if="col.field === PurchaseOrderLinesColumns.OpenValue">
            <span :data-testid="`open-value-${index}`">{{ data[field] }}</span>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #empty>{{ t("no-purchase-orders-found") }} </template>
    </DataTable>

    <ColumnChooser
      v-model:visibleDialog="visible"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      :onSelectAllChange="onSelectAllChange"
      :selectAll="selectAll"
      @resetColumns="resetColumns"
      @columnSelected="onColumnSelected"
      @columnUnselected="onColumnUnselected"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import OpenLines from "./OpenLines.vue";
import { PurchaseOrderLinesColumns } from "@/model/search/purchase-order-search/PurchaseOrderLinesColumns";
import { computed, nextTick, ref, watch } from "vue";
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();
const { t, d } = useI18n();
const route = useRoute();

const searchPurchaseOrder = ref();
const selectedPurchaseOrder = ref<PurchaseOrder>(new PurchaseOrder());
const visible = ref(false);

const props = defineProps<{
  purchaseOrders: PurchaseOrder[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  employeeId: string;
}>();

const emit = defineEmits<{
  (e: "onRowDblClicked", value: PurchaseOrder): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "purchaseOrderListRefresh"): void;
}>();

const goodReceptionSearchColumns = [
  { field: "estimatedArrivalDate", header: t("expected-date"), sortable: true },
  { field: "purchaseOrderNumber", header: t("purchase-order-number"), sortable: true },
  {
    field: "purchaseOrderStatus",
    header: t("status.label"),
    sortable: true,
  },
  {
    field: "supplier.supplierNumber",
    header: t("supplier-number"),
    sortable: true,
  },
  {
    field: "supplier.name",
    header: t("supplier-name"),
    sortable: true,
  },
  { field: "purchaseOrderReference", header: t("po-reference"), sortable: true },
  { field: "openLines", header: t("open-lines"), class: "text-right", sortable: true },
  { field: "openValue", header: t("open-values"), class: "text-right", sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("goodReceptionSearch", goodReceptionSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== PurchaseOrderLinesColumns.PurchaseOrderNumber &&
      col.field !== PurchaseOrderLinesColumns.SupplierNumber
  );
});

const items = [
  {
    label: t("search.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickPurchasOrderListRefresh();
    },
  },
  {
    label: t("search.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? goodReceptionSearchColumns
    : goodReceptionSearchColumns.filter(
        (c) =>
          c.field === PurchaseOrderLinesColumns.PurchaseOrderNumber ||
          c.field === PurchaseOrderLinesColumns.SupplierNumber
      );
};

const onClickPurchasOrderListRefresh = () => {
  emit("purchaseOrderListRefresh");
};

const addGlobalSearchClass = (data: PurchaseOrder | null | undefined) => {
  if (!data || typeof data.id === "undefined") {
    return ["c-search-result-row"];
  }

  return [
    `c-search-result-row c-sr-id-${data.id}`,
    { "c-search-result-row-selected": selectedPurchaseOrder.value?.id === data.id },
  ];
};

watch([() => props.purchaseOrders, () => props.loading], ([purchaseOrders, loading]) => {
  if (loading === false && purchaseOrders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const focusSearchResult = (id = "") => {
  if (props.purchaseOrders.length > 0 && searchPurchaseOrder.value) {
    const element = id === "" ? null : searchPurchaseOrder.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }

    searchPurchaseOrder.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const getStatusTranslation = (purchaseOrder: PurchaseOrder) => {
  switch (purchaseOrder.purchaseOrderStatus) {
    case PurchaseOrderStatus.Open:
      return t("status.open");
    case PurchaseOrderStatus.PartiallyReceived:
      return t("status.partiallyreceived");
    case PurchaseOrderStatus.Received:
      return t("status.received");
    default:
      return "";
  }
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPurchaseNumberClicked = (purchaseOrder: PurchaseOrder) => {
  selectedPurchaseOrder.value = purchaseOrder;
  emit("onRowDblClicked", purchaseOrder);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("onRowDblClicked", event.data);
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  emit("onRowDblClicked", event.data);
};
</script>
<style scoped lang="scss">
.c-purchase-order-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
