import { Supplier } from "@/repository/goods-reception/model/Supplier";
import { PurchaseOrderLine } from "./PurchaseOrderLine";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import { PurchaseOrderDelivery } from "./PurchaseOrderDelivery";
import { PurchaseOrderFreightMethod } from "./PurchaseOrderFreightMethod";
import { PurchaseOrderPaymentTerm } from "./PurchaseOrderPaymentTerm";

export class PurchaseOrder {
  id = "";
  registered = "";
  registeredByEmployeeName = "";
  currencyIso = "";
  companyId = "";
  purchaseOrderNumber = "";
  warehouseId = "";
  supplier = new Supplier();
  purchaseOrderReference = "";
  purchaseOrderLines: PurchaseOrderLine[] = [];
  comment = "";
  openValue = 0;
  estimatedArrivalDate = "";
  purchaseOrderStatus: PurchaseOrderStatus = PurchaseOrderStatus.Open;
  delivery = new PurchaseOrderDelivery();
  paymentTerm = new PurchaseOrderPaymentTerm("", "", 0);
  freightMethod = new PurchaseOrderFreightMethod();
  shippingPrice = 0;
}
