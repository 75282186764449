import { useAuth } from "@cumulus/event-bus";

import { useErrorHandler } from "../ErrorHandler";
import { goodsReceptionApi } from "@/repository/goods-reception/GoodsReceptionApi";
import { GoodsReceptionRequest } from "@/repository/goods-reception/model/GoodsReceptionRequest";
import { CalculatedGoodsReceptionSummaryResponse } from "@/repository/goods-reception/model/CalculatedGoodsReceptionSummaryResponse";
import { CalculateGoodsReceptionSummaryRequest } from "@/repository/goods-reception/model/CalculateGoodsReceptionSummaryRequest";
import { ReceivedQuantity } from "@/repository/goods-reception/model/ReceivedQuantity";

export function useGoodsReception() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const addGoodsReception = async (goodsReceptionRequest: GoodsReceptionRequest): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await goodsReceptionApi.addGoodsReception(authHeaders, goodsReceptionRequest);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const calculateGoodsReceptionSummary = async (
    receivedQuantities: ReceivedQuantity[],
    freightPrice: number
  ): Promise<CalculatedGoodsReceptionSummaryResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      const request = new CalculateGoodsReceptionSummaryRequest(receivedQuantities, freightPrice);
      return await goodsReceptionApi.calculateGoodsReceptionSummary(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    addGoodsReception,
    calculateGoodsReceptionSummary,
  };
}
