<template>
  <div>
    <TabView class="c-tabview" :tabindex="-1" data-testid="information-tab">
      <TabPanel :header="t(`purchase.info-tabs.first-tab`)">
        <div>
          <table>
            <tr>
              <td class="w-full text-sm" v-if="supplier.name">
                <span data-testid="supplier-name">{{ supplier.name }}</span> /
                <span data-testid="supplier-number">{{ supplier.supplierNumber }}</span>
              </td>
              <td class="w-full text-sm" v-else>
                <span>
                  {{ t(`purchase.supplier.no-supplier`) }}
                </span>
              </td>
            </tr>
            <tr>
              <td
                class="w-full text-sm white-space-nowrap overflow-hidden text-overflow-ellipsis"
                data-testid="supplier-address"
              >
                <span v-for="(line, index) in supplier.address.addressLines" :key="line">
                  {{ index > 0 ? ", " : "" }}{{ line }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="w-full text-sm">
                <span data-testid="supplier-postal-code" class="mr-1">{{ supplier.address.postalCode }}</span>
                <span data-testid="supplier-postal-city">{{ supplier.address.city }}</span>
              </td>
            </tr>

            <tr>
              <td class="w-full text-sm" v-if="supplier.phoneNumber">
                <span>{{ t(`purchase.supplier.phone`, { symbol: ": " }) }}</span
                ><span data-testid="supplier-phone-number">{{ supplier.phoneNumber }}</span>
              </td>
            </tr>
            <tr>
              <td class="w-full text-sm" v-if="supplier.contact.email">
                <span>{{ t(`purchase.supplier.email`, { symbol: ": " }) }}</span>
                <span data-testid="supplier-email">{{ supplier.contact.email }}</span>
              </td>
            </tr>
          </table>
        </div>
      </TabPanel>

      <TabPanel :header="t(`purchase.info-tabs.second-tab`)">
        <table>
          <tr>
            <td class="w-full text-sm" data-testid="delivery-name">{{ props.delivery.name }}</td>
          </tr>
          <tr>
            <td class="w-full text-sm" data-testid="delivery-address">
              <span v-for="(line, index) in props.delivery.address.addressLines" :key="line">
                {{ index > 0 ? ", " : "" }}{{ line }}</span
              >
            </td>
          </tr>
          <tr>
            <td class="w-full text-sm" data-testid="delivery-postal-code">
              <span class="mr-1">{{ props.delivery.address.postalCode }}</span>
              <span>{{ props.delivery.address.city }}</span>
            </td>
          </tr>
        </table>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { Supplier } from "@/repository/goods-reception/model/Supplier";
import { PurchaseOrderDelivery } from "@/repository/purchase-order/model/PurchaseOrderDelivery";

const props = defineProps<{
  supplier: Supplier;
  delivery: PurchaseOrderDelivery;
}>();

const { t } = useI18n();
</script>
<style lang="scss" scoped>
:deep(.c-tabview).p-tabview {
  padding-top: 0.11rem;
}
:deep(.c-tabview).p-tabview .p-tabview-nav {
  border-color: transparent;
}

:deep(.c-tabview).p-tabview .p-tabview-panels {
  padding-bottom: 0;
}

:deep(.c-tabview).p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}

.c-address-menu {
  margin-top: -0.2rem;
  position: relative;
  float: right;
  z-index: 1;
}

.c-description {
  color: var(--text-color-secondary);
}
</style>
