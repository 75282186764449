import { httpClient, AuthHeaders } from "@cumulus/http";
import { GoodsReceptionRequest } from "./model/GoodsReceptionRequest";
import { CalculateGoodsReceptionSummaryRequest } from "./model/CalculateGoodsReceptionSummaryRequest";
import { CalculatedGoodsReceptionSummaryResponse } from "./model/CalculatedGoodsReceptionSummaryResponse";

class GoodsReceptionApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? import.meta.env.VITE_APP_API_WAREHOUSE + "/goods-receptions"
        : `${import.meta.env.VITE_APP_API_URL as string}/goods-receptions`;
  }

  public async addGoodsReception(
    authHeaders: AuthHeaders,
    goodsReceptionRequest: GoodsReceptionRequest
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post("", goodsReceptionRequest)
      .then(({ data }) => data);
  }

  public async calculateGoodsReceptionSummary(
    authHeaders: AuthHeaders,
    request: CalculateGoodsReceptionSummaryRequest
  ): Promise<CalculatedGoodsReceptionSummaryResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/summary", request)
      .then((response) => response.data);
  }
}

const goodsReceptionApi = new GoodsReceptionApi();
export { goodsReceptionApi };
