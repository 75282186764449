<template>
  <label>{{ t("status.label") }}</label>
  <div role="list">
    <li
      v-for="(value, index) in purchaseOrderStatusesComputed"
      :key="index"
      class="flex justify-content-between relative align-items-center p-2 border-round-md"
      :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      role="listitem"
    >
      <label
        class="font-light"
        :for="'purchase-order-status-' + value.status.toLowerCase()"
        :data-testid="'purchase-order-status-label-' + value.status.toLowerCase()"
        >{{ t(`status.${value.status.toLowerCase()}`) }}</label
      >
      <Checkbox
        v-model="selectedStatusesComputed"
        :value="value.status.toLowerCase()"
        :inputId="'purchase-order-status-' + value.status.toLowerCase()"
        :data-testid="'purchase-order-status-checkbox-' + value.status.toLowerCase()"
      />
    </li>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  selectedStatuses: PurchaseOrderStatus[];
}>();

const emit = defineEmits<{
  (event: "update:selectedStatuses", value: string[]): void;
}>();

const { t } = useI18n();

const selectedStatusesComputed = computed<string[]>({
  get: () => {
    return props.selectedStatuses.length > 0 ? props.selectedStatuses.map((status) => status.toLowerCase()) : [];
  },
  set: (value: string[]) => {
    emit("update:selectedStatuses", value);
  },
});

const purchaseOrderStatusesComputed = computed(() => {
  const allStatuses = Object.values(PurchaseOrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});
</script>

<style scoped>
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}

h4,
label {
  font-family: var(--font-family);
}
</style>
