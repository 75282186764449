<template>
  <label>{{ t("warehouse") }}</label>
  <Dropdown
    :data-testid="dataTestId"
    :options="warehouses"
    optionLabel="name"
    v-model="selectedWarehouseComputed"
    class="w-full"
    :placeholder="t('placeholder.select', { property: t('warehouse').toLowerCase() })"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { Warehouse } from "../repository/warehouse/model/Warehouse";

const { t } = useI18n();

const props = defineProps<{
  selectedWarehouse?: Warehouse;
  warehouses?: Warehouse[];
  dataTestId?: string;
}>();

const emit = defineEmits<{
  (event: "update:selectedWarehouse", value: Warehouse): void;
}>();

const selectedWarehouseComputed = computed<Warehouse>({
  get: () => {
    return props.selectedWarehouse ?? ({} as Warehouse);
  },
  set: (value: Warehouse) => emit("update:selectedWarehouse", value),
});
</script>

<style scoped>
label {
  font-family: var(--font-family);
}
</style>
