<template>
  <div class="data-table-container w-full">
    <DataTable
      :value="props.purchaseOrderLinesToReceive"
      stripedRows
      id="goods-reception-lines-table"
      class="c-compact-datatable"
      selectionMode="multiple"
      v-model:selection="selectedRows"
      :rowClass="lineStatusClass"
      @update:selection="onSelectionUpdated"
    >
      <Column field="positionNumber" :header="t('headers.posnr')" class="c-margin-auto" />
      <Column field="product.productNumber" :header="t('headers.product-number')" class="c-margin-auto" />
      <Column
        field="product.supplierProductNumber"
        :header="t('headers.supplier-product-number')"
        class="c-margin-auto"
      />
      <Column field="product.gtin" :header="t('headers.gtin')" class="c-margin-auto" />
      <Column field="product.name" :header="t('headers.product-name')" class="c-margin-auto" />
      <Column field="quantity" :header="t('headers.quantity-ordered')" class="c-margin-auto text-right" />
      <Column field="receivedQuantity" :header="t('headers.received')" class="c-margin-auto text-right" />
      <Column field="estimatedArrivalDate" :header="t('headers.eta-date')" class="c-margin-auto text-center">
        <template #body="{ data }">
          <div class="text-center">{{ d(data.estimatedArrivalDate) }}</div>
        </template>
      </Column>
      <Column :header="t('headers.qty')" bodyClass="pr-0 pl-0" class="c-margin-auto">
        <template #body="{ data, index }">
          <div class="flex justify-content-center w-full">
            <InputNumber
              :id="`quantity-to-receive-input-${index}`"
              :data-testid="`quantity-to-receive-input-${index}`"
              :max="data.quantity - data.receivedQuantity"
              :min="0"
              :allow-empty="false"
              v-model="data.quantityToReceive"
              class="w-full m-2"
              inputClass="w-3rem text-right"
              placeholder="0"
              @update:modelValue="() => emit('quantityChanged')"
            />
          </div>
        </template>
      </Column>
      <Column class="pr-0 pl-0 c-margin-auto" :header="t('headers.reference')">
        <template #body="{ data, index }">
          <div class="flex justify-content-center w-full">
            <InputText
              :id="`reference-input-${index}`"
              v-model="data.reference"
              :data-testid="`reference-input-${index}`"
              class="text-center w-full m-2"
              inputClass="w-full"
            />
          </div>
        </template>
      </Column>
      <Column bodyClass="pr-0 pl-0" :header="t('headers.purchase-price')" class="c-margin-auto">
        <template #body="{ data, index }">
          <div class="flex justify-content-center w-full">
            <InputNumber
              v-model="data.purchasePrice"
              class="w-full m-2"
              inputClass="text-right w-4rem"
              :data-testid="`cost-per-unit-input-${index}`"
              :min-fraction-digits="2"
              :max-fraction-digits="2"
              :allow-empty="false"
              :disabled="data.quantityToReceive <= 0"
              mode="currency"
              currencyDisplay="narrowSymbol"
              :currency="props.purchaseCurrencyIso"
              @update:modelValue="onCostChangedInPurchaseLine(data)"
            />
          </div>
        </template>
      </Column>
      <Column :header="t('headers.received-cost')" class="c-margin-auto text-right">
        <template #body="{ data, index }">
          <span :data-testid="`line-price-currency-${index}`">{{
            n(data.receivedCost, "currency", props.clientCurrencyIso)
          }}</span>
        </template>
      </Column>
      <Column :header="t('headers.line-total')" class="c-margin-auto text-right">
        <template #body="{ data, index }">
          <span :data-testid="`line-total-cost-${index}`">{{
            n(data.sumLine, "currency", props.clientCurrencyIso)
          }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { PurchaseOrderLineToReceive } from "@/repository/goods-reception/model/PurchaseOrderLineToReceive";
import { ref } from "vue";

const { t, n, d } = useI18n();

const props = defineProps<{
  purchaseOrderLinesToReceive: PurchaseOrderLineToReceive[];
  selectedLines: PurchaseOrderLineToReceive[];
  initialPurchaseOrderLinesToReceive: PurchaseOrderLineToReceive[];
  clientCurrencyIso: string;
  purchaseCurrencyIso: string;
}>();

const emit = defineEmits<{
  (e: "quantityChanged"): void;
  (e: "costChangedInPurchaseLine", value: PurchaseOrderLineToReceive): void;
  (e: "selectionUpdated", lines: PurchaseOrderLineToReceive[]): void;
}>();

const clearSelectedRows = () => {
  selectedRows.value = [];
  emit("selectionUpdated", selectedRows.value);
};

defineExpose({
  clearSelectedRows,
});

const selectedRows = ref<PurchaseOrderLineToReceive[]>([]);

const onCostChangedInPurchaseLine = (line: PurchaseOrderLineToReceive) => {
  emit("costChangedInPurchaseLine", line);
};

const lineIsModified = (line: PurchaseOrderLineToReceive) => {
  return (
    line.quantityToReceive !== 0 ||
    line.reference !== "" ||
    line.purchasePrice !== props.initialPurchaseOrderLinesToReceive.find((x) => x.id === line.id)?.purchasePrice
  );
};

const lineIsFullyReceived = (line: PurchaseOrderLineToReceive) => {
  return line.quantityToReceive === line.quantity || line.receivedQuantity + line.quantityToReceive >= line.quantity;
};

const lineStatusClass = (line: PurchaseOrderLineToReceive) => {
  if (props.selectedLines.some((x) => x.id === line.id)) return "";
  if (!lineIsModified(line)) return "";
  if (lineIsFullyReceived(line)) return "c-line-done";
  else return "c-line-modified";
};

const onSelectionUpdated = () => {
  emit("selectionUpdated", selectedRows.value);
};
</script>

<style lang="scss" scoped>
.c-compact-datatable :deep(.p-datatable-thead > tr > th.c-margin-auto > div > span) {
  margin: auto;
}

.c-compact-datatable :deep(.p-datatable-tbody > tr.c-line-modified) {
  background-color: var(--goods-reception-items-partially-received) !important;
}

.c-compact-datatable :deep(.p-datatable-tbody > tr.c-line-done) {
  background-color: var(--goods-reception-all-items-received) !important;
}
</style>
