<template>
  <div class="search-container mb-3">
    <div class="w-4 flex c-under-construction">
      <InputText
        :placeholder="t('search.placeholder')"
        class="input-search-product w-full c-product-search"
        ref="inputRef"
      />
      <span class="p-button p-inputgroup-addon product-search-button"><i class="pi pi-search" /></span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const inputRef = ref();

onMounted(() => {
  inputRef.value?.$el.focus();
});
</script>
<style scoped lang="scss">
.c-product-search {
  border-color: var(--select-border);
}
</style>
